export const UserRole = {
  Admin: 0,
  Editor: 1
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const showSideBar = true;

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' }
];

// to be stored in environment variable
export const innerloopFirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const adminRoot = '/app';
export const dashboardRoute = '/app/dashboard';
export const loginRoute = '/user/login';
export const registerRoute = '/user/register';
export const spaceHomeRoute = '/app/spaces';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  // id: 1,
  // title: 'Sarah Kortney',
  // img: '/assets/img/profiles/l-1.jpg',
  // date: 'Last seen today 15:24',
  role: UserRole.Admin
};

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = false;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  // 'bluenavy',
  // 'blueyale',
  // 'blueolympic',
  // 'greenmoss',
  // 'greenlime',
  'purplemonster'
  // 'orangecarrot',
  // 'redruby',
  // 'yellowgranola',
  // 'greysteel',
];

export const localFIleLimit = 5 * 1073741824;
export const oneMB = 1048576;

export const ENTITY_KIND = {
  CONTENT: 1,
  STORY: 2
};

export const DUB_STATUS = {
  PROCESSING: 3,
  ERROR: 4,
  READY: 2
};

export const INTERVAL_TYPE = {
  None: 0,
  Second: 1,
  Minute: 2,
  Hour: 3,
  Day: 4,
  Week: 5,
  Month: 6,
  Year: 7,
  LifeTime: 8
};

export const AI_OPERATION_TYPE = {
  None: 0,
  AutoTranscribe: 1,
  AutoDubbing: 2,
  AutoClipping: 3,
  Repurpose: 4,
  Description: 5,
  ImageInSight: 6,
  Narrative: 7,
  Chat: 8,
  StoryInsights: 9
};

export const ENTITY_TYPES = {
  STORY: 2,
  CONTENT: 1
};
