/* eslint-disable no-unreachable */
const { AI_OPERATION_TYPE } = require('constants/defaultValues');

const defaultAiProperties = {
  transcribeEnabled: false,
  dubbingEnabled: false,
  clippingEnabled: false,
  repurposeEnabled: false,
  describeEnabled: false,
  imageInsightsEnabled: false,
  narrativeEnabled: false,
  chatEnabled: false,
  storyInsightsEnabled: false
};

const getAiProperties = (aiValues) => {
  try {
    if (!aiValues) {
      return defaultAiProperties;
    }

    const transcribeEnabled =
      aiValues?.find(
        (f) => f.aiOperationType === AI_OPERATION_TYPE.AutoTranscribe
      )?.enable ?? false;

    const dubbingEnabled =
      aiValues?.find((f) => f.aiOperationType === AI_OPERATION_TYPE.AutoDubbing)
        ?.enable ?? false;

    const clippingEnabled =
      aiValues?.find(
        (f) => f.aiOperationType === AI_OPERATION_TYPE.AutoClipping
      )?.enable ?? false;

    const repurposeEnabled =
      aiValues?.find((f) => f.aiOperationType === AI_OPERATION_TYPE.Repurpose)
        ?.enable ?? false;

    const describeEnabled =
      aiValues?.find((f) => f.aiOperationType === AI_OPERATION_TYPE.Description)
        ?.enable ?? false;

    const imageInsightsEnabled =
      aiValues?.find(
        (f) => f.aiOperationType === AI_OPERATION_TYPE.ImageInSight
      )?.enable ?? false;

    const narrativeEnabled =
      aiValues?.find((f) => f.aiOperationType === AI_OPERATION_TYPE.Narrative)
        ?.enable ?? false;

    const chatEnabled =
      aiValues?.find((f) => f.aiOperationType === AI_OPERATION_TYPE.Chat)
        ?.enable ?? false;

    const storyInsightsEnabled =
      aiValues?.find(
        (f) => f.aiOperationType === AI_OPERATION_TYPE.StoryInsights
      )?.enable ?? false;

    return {
      transcribeEnabled,
      dubbingEnabled,
      clippingEnabled,
      repurposeEnabled,
      describeEnabled,
      imageInsightsEnabled,
      narrativeEnabled,
      chatEnabled,
      storyInsightsEnabled
    };
  } catch (error) {
    return defaultAiProperties;
  }
};

module.exports = {
  getAiProperties,
  defaultAiProperties
};
